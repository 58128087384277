#Report {
    box-sizing: border-box;
    width: 100%;
    // max-width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: 100%;
    .close {
        align-self: flex-end;
        display: flex;
        justify-content: flex-end;
        margin: 2.5vw 5vw 2.5vw 0;
        img {
            width: 100%;
            transform: scale(2);
        }
        @media screen and (max-width:820px) {
            img {
                max-width: 100%;
                transform: scale(1);
            }
        }
      
    }
    .form {
        box-sizing: border-box;
        background-color: black;
        border: 1px solid white;
        padding: 3vw;
        margin-top: 0;
        margin-bottom: 2.5vw;
        max-width: 40%;
        text-align: center;
        // width: 100%;
        @media screen and (max-width:600px) {
            max-width: 100%;
            width: 100%;
            border-left: none;
            border-right: none;
            // padding: 3vw 9vw;
            padding: 2em;
        }
        .label-group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 100%;
            justify-content: space-between;
            .date {
                flex-basis: 50%;
                max-width: 50%;
            }
            .time {
                flex-basis: 20%;
                max-width: 20%;
            }
            .second {
                flex-basis: 20%;
                max-width: 20%;
            }
            // & > .label-input {
            //     margin: 1vw 0;
            // }
        }
        .label-input {
            display: flex;
            flex-direction: column;
            margin: 1vw 0;
            // width: 100%;
            span {
                text-align: left;
                text-transform: uppercase;
                margin: 1vw 0;
                font-size: 1.25vw;
                font-family: GunarBold;
                @media screen and (max-width:600px) {
                    font-size: 2vw;
                }
                &.info {
                    font-size: 1vw;
                    font-family: Gunar;
                    text-transform: initial;
                    // margin: 0;
                    @media screen and (max-width:600px) {
                        font-size: 1.5vw;
                    }
                }
            }
            select {
                box-sizing: border-box;
                height: 3vw;
                outline: none;
                font-size: 1.35vw;
                padding: .5vw 0;
                // padding: 1.25vh 2vw;
                outline: none;
                border-radius: 0;
                // line-height: 2vw;
                color:black;
                background-color: #fff;
                border: none;

                @media screen and (max-width:600px) {
                    height: 5vw;
                }

                &:required:invalid {
                    color: gray;
                }

                option {
                    // padding: 1.25vh 2vw;
                    line-height: 2vw;
                    color: black;
                    // &:disabled {
                    //     color :gray;
                    // }
                }
            }
            input {
                // height: 2vw;
                border: none;
                padding: 1vw 2vw;
                font-size: 1.25vw;
                &[type=date].disabled::-webkit-calendar-picker-indicator { 
                
                        display: none;
                      
                }
            }
        }
        .btn__submit {
            font-family: GunarBold;
            background-color:$yellow1;
            outline: none;
            // max-width: 25%;
            border: none;
            padding: 2vw 2.5vw;
            font-size: 2vw;
            margin: 2.25vw auto 0;
        }
    }
}