// COLOR BASE
$white: #fff;
$base: #070706;
$yellow1: #fee100;
$yellow2: #ffdc3b;
$grey1: #272727;
$grey2: #1a1a1a;
$grey3: #3d3d3d;

// VIEWPORT
$mobile: 820px;
$large-screen: 1800px;

// LOOP LIST
$mobile-view: mobile iphonex;
$ipad-view: ipad ipadpro;
