.footer{
	position: relative;
	bottom: 0;
	width: 100%;
    padding: 1% 0;
    background: #000000;
	@media screen and (max-width: $mobile){
		padding: 3% 0 6%;
	}

	.logo-cont,.text{
		width: 30%;
		margin: 0 auto;
		text-align: center;

		@media screen and (max-width: $mobile){
			width: 90%;
		}
	}

	.logo-cont {
		img {
			margin: 0 .5vw;
			// height: 3vw;

			@media screen and (max-width: $mobile) {
				 height: 5.5vw;
				 margin: 0 2vw 2vw;
			}

			@media screen and (max-width: $mobile) and (orientation: landscape){
				height: 4vw;
			}
		}
	}

	ul{
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: $mobile){
			width: 55%;
			margin-bottom: 3%;
		}

		li{
			flex-basis: calc(100%/3);
			text-align: center;

			@media screen and (max-width: $mobile){
				flex-basis: calc(100%/3.15);
			}
		}
	}
	.text{
		margin: 1% auto 0;
		text-align: center;
		font-size: .6vw;

		@media screen and (max-width: $mobile){
			font-size: 2.1vw;
			line-height: 1.2;
		}

		@media screen and (max-width: $mobile) and (orientation: landscape){
			font-size: 1.5vw;
		}
	}
}
