.nickname {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-top: 7.5%;
    padding-right: 2.5%;
    span {
        font-size: 1.15vw;
        text-align: right;
        width: 100%;
        font-style: italic;
    }
    .tabCurrent {
        margin-top: 1vh;
        display: flex;   
        align-items: center;
        justify-content: flex-end;
        span {
            background-color: $grey2;
            padding: 1.75vh 1.25vw;
        }
        .btn__logout {
            background-color: $yellow1;
            font-family: GunarBold;
            font-weight: 900;
            text-transform: uppercase;
            outline: none;
            border: none;
            padding: 1vh 1.25vw;
            font-size: 1.5vw;
            
        }
    }
    @media screen and (max-width:600px) {
        // top: 15%;
        padding-top: 1em;
        span {
            font-size: 1.75vw;
        }
        .tabCurrent {
            margin-top: 1vw;
            span {
                padding:2vw 1.5vw;
                font-size: 1.75vw;
            }
            .btn__logout {
                padding:2vw 1.5vw;
                font-size: 1.75vw;
            }
        }
    }
}