#Login{
    box-sizing: border-box;
    // max-width: 80%;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin: 0 auto;
    padding: 0 5vw;

    img {
        max-width: 100%;
        height: auto;
        width: auto;
        display: block;
        &:nth-of-type(1) {
            // margin-bottom: 2.5%;
        }
        @media screen and (max-width: $mobile) and (orientation:landscape)  {
            max-width: 35%;
        }
    }
}