#Home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .group-button {
        flex-grow: 1;
        // height: 100%;
        // max-width: 20%;
        margin: 10vw auto 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (orientation:portrait) {
            justify-content: center;
        }
    }
    @media screen and (max-width: $mobile){
        max-width: 100%;
        margin: 0 auto;
    }
    a {
        display: inline-block;
        &:nth-of-type(1),
        &:nth-of-type(2) {
            margin-bottom: 1.5vw;
        }
    }
    img {
        width: auto;
        height: auto;
        max-width: 30vw;
        &:nth-of-type(3) {
            margin-bottom: 0;
        }
        @media screen and (orientation:portrait) {
            max-width: 50vw;
            margin-bottom: 2.5vh;
        }
    }

}
