.nav{
	width: 100%;
	background: rgba(0,0,0,.9);
	position: fixed;
	z-index:10;
	border-bottom: 1px solid #fff;

	.cont_nav{	
		width: 65%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		font-family: GunarBold;
		
		@media screen and (max-width: $mobile){
			display: none;
		}

		.logo{
			margin-right: 1%;
			flex-basis: 14%;
			cursor: pointer;
		}

		> ul{
			flex-basis: 85%;
			display: flex;
			align-items: center;
			height: 100%;
			margin-left: 2vw;

			> li{
				text-align: center;
				flex-basis: 20%;
				text-transform: uppercase;
				padding: 3% 0;

				a{
					display: inline-block;
					cursor: pointer;
					color: #fff;
				}

				&:hover {
					background: url('~assets/img/homepage/Button_Hover.jpg') no-repeat;
					background-size: 100% 100%;
					
					a {
						color: #000;
					}
				}

				&.active{
					color: $yellow1;
				}

				&.dropdown{
					display: block;
					position: relative;

					.list{
						display: none;
						position: absolute;
					    top: 102%;
					    width: 100%;
						height: auto;
					}

					&:hover{

						.list{
							display: block;
							// padding: 5% 0;
							background: rgba(8, 8, 8, 0.76);
							
							li{
								text-transform: capitalize;
								
								&:hover{
									background: rgba(8, 8, 8, 0.9);
									a {
										color: $yellow1;
									}
								}
								
								a {
									color: #fff;
									padding: 7% 0%;
									width: 100%;
								}
							}
						}
					}
				}
			}

		}
	}

	.navbar_mobile{
		position: fixed;
		width: 100%;
		z-index: 99;
		display: none;
		
		@media screen and (max-width: $mobile){
			display: block;
		}
		
		.nav-cont {
			background: $base;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width: $mobile) and (orientation: landscape) {
				// width: 85%
			}

			.hamburger {
				background: $grey2;

				@media screen and (max-width: $mobile) and (orientation: landscape) {
					padding: 1vw 2vw;
				}

				@media screen and (max-width:600px) {
					padding: 15px;
				}

				.hamburger-box {
					top: 2px;
					width: 8vw;

					@media screen and (max-width: $mobile) and (orientation: landscape) {
						width: 5vw;
						height: 5vw;
					}

					@media screen and (max-width:600px) {
						// top: 2px;
						width: 8vw;
						height: 24px;
					}
				}

				.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
					width: 8vw;
					background-color: $white;

					@media screen and (max-width: $mobile) and (orientation: landscape) {
						width: 5vw;
						height: .3vw;
					}

					@media screen and (max-width:600px) {
						width: 8vw;
						height: 5px;
					}
				}
			}

			.logo {
				width: 25%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);

				@media screen and (max-width: $mobile) and (orientation: landscape) {
					width: 12%;
					// left: 42%;
				}

				@media screen and (max-width:600px) {
					width: 25%;
				}
			}

			.download {
				margin-right: 3vw;
				cursor: pointer;
				background: url('~assets/img/floating/Button_Download.png') no-repeat;
				background-size: contain;
				width: 25%;
				height: 6.5vw;

				@media screen and (max-width: $mobile) and (orientation: landscape) {
					width: 15%;
					height: 3.5vw;
				}
			}
		}

		.overlay-menu{
			position: absolute;
			width: 100%;
			height: 100vh;
			visibility: hidden;
			&.active{
				background: rgba(0, 0, 0, 0.55);
				visibility: initial;
			}
		}

		.menu {
			position: absolute;
			left: -70%;
			width: 54%;
			background: $grey2;
			padding: 8% 8% 0;
			transition: all .2s ease-in-out;
			color: $white;
			height: 100vh;
			overflow-y: auto;
			z-index: 99;
			border-top: .5px solid $yellow1;

			&.active {
				left: 0;
			}

			> div, > ul, > img {
				margin-bottom: 3vw;
			}

			@media screen and (max-width: $mobile) and (orientation: landscape) {
				padding: 3vw 4vw;
				width: 30%;
				height: 76vh;
			}

			ul {
				
				a {
					text-decoration: none;
					color: $white;

					&:focus {
						outline: none;
					}

					@media screen and (max-width: $mobile) and (orientation: landscape) {
						font-size: 2vw;
					}
				}

				padding: 0;


				li {
					margin-bottom: 7%;
					list-style: none;
					font-size: 4vw;
					cursor: pointer;
					text-transform: uppercase;
					font-family: GunarBold;
					a{
						display: block;
						cursor: pointer;
					}
					&.collapse{
						position: relative;
						a{						
							position: relative;

							&:after{
								content: '';
								background: url('~assets/img/sidebar_closed.png') no-repeat top center;
								background-size: contain;
								width: 4.5vw;
								height: 4.5vw;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								right: 0;
								transition: all .3s ease-in-out;

								@media screen and (max-width: $mobile) and (orientation: landscape) {
									width: 2vw;
									height: 2vw;
								}
							}
						}

						&.open{
							a{							
								&:after{
									transform: translateY(-50%) rotate(90deg);
								}
							}
							.coll-content{
								.coll-menu{
									margin-top: 4%;
								}
							}
						}

						.coll-content{
							list-style-type: none;
							padding: 0;
							overflow: hidden;
							.coll-menu{
								margin-top: -104%;
								transition: all .5s;
								a:after{
									width: 0;
									height: 0;
								}
							}

							li{
								background: $grey1;
								font-family: Gunar;
								text-transform: capitalize;
								margin-bottom: 1vw;
								a{
									cursor: pointer;
									padding: 3% 8%;
								}
							}

						}
					}
				}
			}

			p {
				font-size: 2.5vw;
				font-style: italic;
			}
		}

		
	}
}
