@import 'fonts';
@import 'variables';
@import 'mixins';

* {
    // box-sizing: border-box;
}

html {
	// scroll-behavior: smooth;
	height: 100%;
}

body{
	margin: 0;
	padding: 0;
	background: url('~assets/img/BG.jpg') no-repeat top center;
	background-size: 100% auto;
	background-attachment: fixed;
	// display: flex;
	// flex-direction: column;
	@media screen and (max-width: $mobile) {
		background: url('~assets/img/BG_Mobile.jpg') no-repeat top center;
		background-size: cover;
	}
	@media screen and (orientation: portrait) {
		background: url('~assets/img/BG_Mobile.jpg') no-repeat top center;
		background-size: cover;
	}
	color: $white;
	font-family: Gunar;
	height: 100%;

	@media screen and (max-width: $mobile){
		font-size: 3vw;
	}

	.img-fluid{
		max-width: 100%;
		height: auto;
		vertical-align: middle;
	}

	ul{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	p{
		margin: 0;
	}
	a{
		text-decoration: none;
	}
}

#root {
	// min-height: 100%;
	height: 100%;
}

.wrapper{
    position: relative;
	height: 100%;
	

	.head_title{
		background: url('../img/bg.png') no-repeat top center;
		background-size: contain;
		width: 54%;
		height: 15vw;
		margin: 0 auto;
		position: absolute;
		top: -14%;
		transform: translateY(14%);
		z-index: 1;
		left: 0;
		right: 0;
		align-items: center;
		display: none;

		@media screen and (max-width: $mobile){
			display: flex;
		}

		@media screen and (max-width: $mobile) and (orientation: landscape) {
			width: 30%;
			height: 8vw;
			top: -8.1%;
		}

		p{
			width: 73%;
			margin: 0 auto;
			font-size: 4.3vw;
			text-transform: uppercase;
			text-align: center;
			color: $yellow1;
			font-family: GunarBold;
			line-height: 1;

			@media screen and (max-width: $mobile) and (orientation: landscape) {
				font-size: 2vw;
			}

		}

	}
}

.btn {
    cursor: pointer;
    outline: none;
    &.off {
        cursor: default;
    }
}

.content-container {
    // padding-top: 5%;
	// height: 100%;
	box-sizing: border-box;
	// padding-top: 15%;
	min-height: 100%;
	// flex-grow: 1;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: $mobile) {
        padding-top: 15%;
		// height: 84.6%;
		display: flex;
		flex-direction: column;
		// height: auto;
	}

	@media screen and (max-width: $mobile) and (orientation: landscape) {
		padding-top: 8% !important;
	}

	.content {
		width: 60%;
		margin: auto;

		@media screen and (max-width: $mobile) {
            width: 90%;
		}
		
		&__title {
            text-align: center;
            font-size: 2.8vw;
            font-family: GunarBold;
			margin: 2.5vw auto 5vw;
			text-transform: uppercase;

            @media screen and (max-width: 767px) {
                margin: 5vw auto 7vw;
                font-size: 5vw;
                border-bottom: 1vw solid $yellow1;
                padding-bottom: 2vw;
				width: 50%;
			}
			
			@media screen and (max-width: $mobile) and (orientation: landscape) {
				font-size: 3vw;
            }
        }
	}
}

#Error {
	width: 100%;
	min-height: 100%;
	// height: 100%;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 3vw;
	color:white;
	font-family: GunarBold;
	.btn__submit {
		font-family: GunarBold;
		background-color:$yellow1;
		outline: none;
		// max-width: 25%;
		border: none;
		padding: 2vw 2.5vw;
		font-size: 2vw;
		margin: 2.25vw auto 0;
	}
}

@import 'partial/hamburger';
@import 'partial/navbar';
@import 'partial/footer';
@import 'partial/userinfo';
@import 'partial/popup';
@import 'pages/report';
@import 'pages/home';
@import 'pages/login';
@import 'pages/status';