#Status {
    width: 100%;
    .close {
        display: flex;
        justify-content: flex-end;
        margin: 2.5vw 5vw 2.5vw 0;
        img {
            // width: 100%;
            transform: scale(2);
        }
        @media screen and (max-width:820px) {
            img {
                max-width: 100%;
                transform: scale(1);
            }
        }
    }
    .tableStatus {
        max-height: 50vh;
        border:1px solid white;
        background: black;
        width: 50%;
        margin: 2.5vw auto;
        overflow-y:  scroll;
        padding: 3vw;
        box-sizing: border-box;
        @media screen and (orientation:portrait) {
            width: 100%;
            border-left: none;
            border-right: none;
            padding: 3vw 9vw;
            table {
                th {
                    font-size: 2.5vw;
                }
                td {
                    font-size: 2.5vw;
                }
           }
        }
        table {       
            box-sizing: border-box;
            border-collapse: collapse;
            width: 100%;
            margin: 0 auto;
            font-size: 1.5vw;
            thead {
                tr {
                    th {
                        background: $yellow1;
                        color:black;
                        width: 30%;
                        padding: 1vh 0;
                        font-family: GunarBold;
                        // padding-right: 5%;
                    }

                    th.keterangan {
                        background: $yellow1;
                        color:black;
                        width: 40%;
                        padding: 1vh;
                        font-family: GunarBold;
                    }
                }
            }
            tbody {
                tr:nth-child(odd) {
                    td {
                        background: $grey1;
                        text-align: center;
                        padding: 1vh;
                        color:white;
                    }

                    .keterangan {
                        text-align: left;
                    }
                }
                tr:nth-child(even) {
                    td {
                        background: $grey2;
                        text-align: center;
                        padding: 1vh;
                        color:white;
                    }

                    .keterangan {
                        text-align: left;
                    }
                }
            }
        }
    }
}