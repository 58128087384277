@font-face{
	font-family: Gunar;
	src: url('../fonts/GunarMedium.ttf');
}

@font-face{
	font-family: GunarBold;
	src: url('../fonts/Gunar\ Bold.ttf');
}

@font-face{
	font-family: GunarItalic;
	src: url('../fonts/Gunar\ Italic.ttf');
}