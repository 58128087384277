.modal {
    background: black;
    border: 1px solid white;
    font-size: 3vw;
    &__success {
        box-sizing: border-box;
        max-width: 50%;
        width: 100%;
        padding: 7.5% 5%;
        // height: 50%;
        // max-height: 50%;
        display: flex;
        justify-content:center;
        align-items: center;
        @media (orientation:portrait) {
            max-width: 80%;
        }
        &>div {
            display: flex;
            flex-direction: column;
            // text-align: center;
            p {
                font-size: 2.5vw;
                font-family: GunarBold;
            }
            .btn {
                font-family: GunarBold;
                background-color:$yellow1;
                outline: none;
                // max-width: 25%;
                border: none;
                padding: 1.5vw 5vw;
                font-size: 2.5vw;
                // max-width: 60%;
                align-self: center;
                margin: 2.3vw auto 0;
            }
        }
    }
    &__rules {
        // box-sizing: border-box;
        max-width: 70%;
        // max-height: 75%;
        width: 100%;
        // height: 100%;
        padding: 4.5% 2.5%;
        // padding: 7.5% 5%;
        // height: 50%;
        // max-height: 50%;
        // display: flex;
        // justify-content:center;
        // align-items: center;
        @media (orientation:portrait) {
            max-width: 90%;
        }
        &>div {
            display: flex;
            flex-direction: column;
            justify-content:flex-start;
            // box-sizing: border-box;
            overflow-y: auto;
            // max-height: 50vh;
            // max-height: 100%;
            // text-align: center;
            ol {
                margin-top: 0;
                margin-bottom: 0;
                padding: 0;
                padding-left: 3vw;
            }
            li {
                font-size: 2vw;
                font-family: GunarBold;
                margin-bottom: .75vh;
                @media screen and (orientation:portrait) {
                    font-size: 3vw;
                }
            }
            .btn {
                font-family: GunarBold;
                background-color:$yellow1;
                outline: none;
                // max-width: 25%;
                border: none;
                padding: 1.5vw 5vw;
                font-size: 2.5vw;
                // max-width: 60%;
                align-self: center;
                margin: 2.3vw auto 0;
            }
        }
    }
}

.closeButton {
    fill: white;
}